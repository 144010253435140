<template>
  <div id="app">
	  <a-config-provider :locale="zhCN">
		<router-view />
	  </a-config-provider>

	<!-- <router-view /> -->
  </div>
</template>

<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
import moment from 'moment';
import 'moment/locale/zh-cn';

moment.locale('zh-cn');
export default {
  name: 'App',
  data () {
      return {
        zhCN
      }
  }	
}
</script>

<style>
	.imgUploadContent{	
		width: 120px;
		height: 120px;
		position: relative;
	}
	.flex-column {
		flex-direction: column;
	}
	.clear-both{
		clear: both;
	}
	.flex-row {
		flex-direction: row;
	}
	.flex {
		display: flex;
	}
	.justify-start {
		justify-content: flex-start;
	}

	.justify-end {
		justify-content: flex-end;
	}

	.justify-center {
		justify-content: center;
	}

	.justify-between {
		justify-content: space-between;
	}

	.justify-around {
		justify-content: space-around;
	}
	.align-start {
		align-items: flex-start;
	}

	.align-end {
		align-items: flex-end;
	}

	.align-center {
		align-items: center;
	}
	.flex-wrap{
		flex-wrap: wrap;
	}
	.clear-both{
		clear: both;
	}
	.text-bold{
		font-weight: bold;
	}
	.text-center{
		text-align: center;
	}
	.text-left{
		text-align: left;
	}
	.text-right{
		text-align: right;
	}
	.fs10{
		font-size: 10px; 
	}
	.fs12{
		font-size: 12px; 
	}
	.fs14{
		font-size: 14px; 
	}
	.fs16{
		font-size: 16px; 
	}
	.fs18{
		font-size: 18px; 
	}
	.fs20{
		font-size: 20px; 
	}
	.fs22{
		font-size: 22px; 
	}
	.fs24{
		font-size: 24px;
	}
	.fs26{
		font-size: 26px;
	}
	.fs28{
		font-size: 28px;
	}
	.fs30{
		font-size: 30px;
	}
	.fs32{
		font-size: 32px;
	}
	.fs34{
		font-size: 34px;
	}
	.fs36{
		font-size: 36px;
	}
	.fs38{
		font-size: 38px;
	}
	.fs40{
		font-size: 40px;
	}
	.mt5{
		margin-top: 5px !important;
	}
	.mt10{
		margin-top: 10px !important;
	}
	.mt25{
		margin-top: 25px !important;
	}
	.mt20{
		margin-top: 20px !important;
	}
	.mt30{
		margin-top: 30px !important;
	}
	.mt40{
		margin-top: 40px !important;
	}
	.mt50{
		margin-top: 50px !important;
	}
	.mt60{
		margin-top: 60px !important;
	}
	.mb10{
		margin-bottom: 10px !important;
	}
	.mb20{
		margin-bottom: 20px !important;
	}
	.mb30{
		margin-bottom: 30px !important;
	}
	.mb40{
		margin-bottom: 40px !important;
	}
	.mb50{
		margin-bottom: 50px !important;
	}
	.mb60{
		margin-bottom: 60px !important;
	}
	.ml5{
		margin-left: 5px !important;
	}
	.ml10{
		margin-left: 10px !important;
	}
	.ml20{
		margin-left: 20px !important;
	}
	.ml30{
		margin-left: 30px !important;
	}
	.ml40{
		margin-left: 40px !important;
	}
	.ml50{
		margin-left: 50px !important;
	}
	.ml60{
		margin-left: 60px !important;
	}
	.mr10{
		margin-right: 10px !important;
	}
	.mr20{
		margin-right: 20px !important;
	}
	.mr15{
		margin-right: 15px !important;
	}
	.mr30{
		margin-right: 30px !important;
	}
	.mr40{
		margin-right: 40px !important;
	}
	.mr50{
		margin-right: 50px !important;
	}
	.mr60{
		margin-right: 60px !important;
	}
	.pt10{
		padding-top: 10px;
	}
	.pt20{
		padding-top: 20px;
	}
	.pt30{
		padding-top: 30px;
	}
	.pt40{
		padding-top: 40px;
	}
	.pt50{
		padding-top: 50px;
	}
	.pt60{
		padding-top: 60px;
	}
	.p10{
		padding: 10px;
	}
	.p20{
		padding: 20px;
	}
	.p30{
		padding: 30px;
	}
	.p40{
		padding: 40px;
	}
	.p50{
		padding: 50px;
	}
	.p60{
		padding: 60px;
	}
	.pb10{
		padding-bottom: 10px;
	}
	.pb20{
		padding-bottom: 20px;
	}
	.pb30{
		padding-bottom: 30px;
	}
	.pb40{
		padding-bottom: 40px;
	}
	.pb50{
		padding-bottom: 50px;
	}
	.pb60{
		padding-bottom: 60px;
	}
	.pb70{
		padding-bottom: 70px;
	}
	.pb80{
		padding-bottom: 80px;
	}
	.pb90{
		padding-bottom: 90px;
	}
	.pb100{
		padding-bottom: 100px;
	}

	.pl10{
		padding-left: 10px;
	}
	.pl20{
		padding-left: 20px;
	}
	.pl30{
		padding-left: 30px;
	}
	.pl40{
		padding-left: 40px;
	}
	.pl50{
		padding-left: 50px;
	}
	.pl60{
		padding-left: 60px;
	}
	.pr10{
		padding-right: 10px;
	}
	.pr20{
		padding-right: 20px;
	}
	.pr30{
		padding-right: 30px;
	}
	.pr40{
		padding-right: 40px;
	}
	.pr50{
		padding-right: 50px;
	}
	.pr60{
		padding-right: 60px;
	}
	.w-100{
		width: 100%
	}
	.w-80{
		width: 80%
	}
	.w-50{
		width: 50%
	}
	.w-30{
		width: 33%
	}
	.w-20{
		width: 20%
	}
	.w-25{
		width: 25%
	}
	.w200{
		width: 200px
	}
	.ellipsis-1 {
		display: -webkit-box;
		overflow: hidden;
		text-overflow: ellipsis;
		word-wrap: break-word;
		word-break: break-all;
		white-space: normal !important;
		line-clamp: 1;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
	}
	.ellipsis-2 {
		display: -webkit-box;
		overflow: hidden;
		text-overflow: ellipsis;
		word-wrap: break-word;
		word-break: break-all;
		white-space: normal !important;
		line-clamp: 2;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
	.ellipsis-3 {
		display: -webkit-box;
		overflow: hidden;
		text-overflow: ellipsis;
		word-wrap: break-word;
		word-break: break-all;
		white-space: normal !important;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
	}
	.ellipsis-4 {
		display: -webkit-box;
		overflow: hidden;
		text-overflow: ellipsis;
		word-wrap: break-word;
		word-break: break-all;
		white-space: normal !important;
		-webkit-line-clamp: 4;
		-webkit-box-orient: vertical;
	}
	@media screen and (max-width: 750px) {
		.el-message-box {
			width: 70% !important;
		}
	}
	.cp{
		cursor: pointer;
	}
	.psa{
		position: absolute
	}
	.psr{
		position: relative;
	}
	.oh{
		overflow: hidden;
	}
	table thead tr th.must div:before {
		display: inline-block;
		content:'*';
		color:#ff1818;
		margin-right: 3px;
	}
	.must_before:before{
		display: inline-block;
		content:'*';
		color:#ff1818;
		margin-right: 3px; 
	}
	.color000{
		color: #000;
	}
	.color222{
		color: #222;
	}
	.color24{
		color: #242424;
	}
	.color333{
		color: #333;
	}
	.colorc0{
		color: #C0C0C0;
	}
	.colord0{
		color: #d0d0d0;
	}
	.coloraaa{
		color: #AAAAAA;
	}
	.colorddd{
		color: #DDDDDD;
	}
	.colorccc{
		color: #CCCCCC;
	}
	.color555{
		color:#555 ;
	}
	.color666{
		color:#666 ;
	}
	.color777{
		color:#777 ;
	}
	.color999{
		color: #999999;
	}
	.colorcm{
		color:#E04146;
	}
	.colorA5A5A5{
		color: #a5a5a5;
	}
	.color599CFB{
		color:#599CFB
	}
	.bg-cm{
		background-color:#E04146;
	}
	.text-bottom-line{
		text-decoration:underline
	}
	.border-bottom{
		border-bottom: 1upx solid #f2f2f2;
	}
	.text-center-line{
		text-decoration: line-through
	}
	.colorEEE{
		color: #EEEEEE;
	}
	.pageCentent{
		background-color: #fff;padding: 16px;margin-bottom: 20px;
	}
</style>
