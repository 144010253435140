//日期范围搜索
function betweenDateFormat(dateArr) {
	return dateArr[0] + " 00:00:00 - " + dateArr[1] + " 23:59:59"
}

//时间戳转时间格式
function timeToDate(dte, flag = false) {
	var date = new Date(dte * 1000) //如果date为13位不需要乘1000
	var Y = date.getFullYear() + '-'
	var M =
		(date.getMonth() + 1 < 10 ?
			'0' + (date.getMonth() + 1) :
			date.getMonth() + 1) + '-'
	var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate())
	var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
	var m =
		(date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
	var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
	if (flag) {
		return Y + M + D
	} else {
		return Y + M + D + ' ' + h + m + s
	}
}

function time2date(micro_second) {
	var time = {}
	// 总秒数
	var second = Math.floor(micro_second)
	// 天数
	time.day = PrefixInteger(Math.floor(second / 3600 / 24), 2)
	// 小时
	time.hour = PrefixInteger(Math.floor((second / 3600) % 24), 2)
	// 分钟
	time.minute = PrefixInteger(Math.floor((second / 60) % 60), 2)
	// 秒
	time.second = PrefixInteger(Math.floor(second % 60), 2)

	var newtime = ''
	if (time.day > 0) {
		newtime =
			time.day +
			'天' +
			time.hour +
			'小时' +
			time.minute +
			'分' +
			time.second +
			'秒'
	} else {
		if (time.hour != 0) {
			newtime = time.hour + '小时' + time.minute + '分' + time.second + '秒'
		} else {
			newtime = time.minute + '分' + time.second + '秒'
		}
	}
	return newtime
}

function timeToDateObj(micro_second) {
	var time = {}
	// 总秒数
	var second = Math.floor(micro_second)
	// 天数
	time.day = Math.floor(second / 3600 / 24)
	// 小时
	time.hour = Math.floor((second / 3600) % 24)
	// 分钟
	time.minute = Math.floor((second / 60) % 60)
	// 秒
	time.second = Math.floor(second % 60)

	return time

}

//货币格式化
function formatMoney(number, places, symbol, thousand, decimal) {
	number = number || 0
	places = !isNaN((places = Math.abs(places))) ? places : 2
	symbol = symbol !== undefined ? symbol : '￥'
	thousand = thousand || ','
	decimal = decimal || '.'
	var negative = number < 0 ? '-' : '',
		i = parseInt((number = Math.abs(+number || 0).toFixed(places)), 10) + '',
		j = (j = i.length) > 3 ? j % 3 : 0
	return (
		symbol +
		negative +
		(j ? i.substr(0, j) + thousand : '') +
		i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousand) +
		(places ?
			decimal +
			Math.abs(number - i)
			.toFixed(places)
			.slice(2) :
			'')
	)
}

function throttle(fn, context, delay) {
	clearTimeout(fn.timeoutId)
	fn.timeoutId = setTimeout(function () {
		fn.call(context)
	}, delay)
}

// 时间格式化输出，如11:03 25:19 每1s都会调用一次
function dateformat(micro_second) {
	var time = {}
	// 总秒数
	var second = Math.floor(micro_second / 1000) // 天数
	time.day = PrefixInteger(Math.floor(second / 3600 / 24), 2) // 小时
	time.hour = PrefixInteger(Math.floor((second / 3600) % 24), 2) // 分钟
	time.minute = PrefixInteger(Math.floor((second / 60) % 60), 2) // 秒
	time.second = PrefixInteger(Math.floor(second % 60), 2)
	return time
}

//不足位数前面补0
function PrefixInteger(num, length) {
	return (Array(length).join('0') + num).slice(-length)
}

//验证是否是手机号
function isPhoneNumber(str) {
	var myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
	if (!myreg.test(str)) {
		return false
	} else {
		return true
	}
}
const judgmentHttp = (url) => {
	if (!url) {
		return url
	}
	let str = url.toUpperCase()
	if (str.indexOf('HTTP') == 0) {
		return url
	} else {
		return window.HOST + url
	}
}
const formatDate = (date, type = 's', iszero = true) => {
	if (date && isNaN(date)) {
		let n = (date.split(':')).length - 1;
		if (n == 2) {
			return date
		}
	}
	var time = new Date(date);
	var year = time.getFullYear(); // 获取年
	var month = time.getMonth() + 1; // 月份是从0开始
	var day = time.getDate(); // 获取日
	var hours = time.getHours(); // 获取时间 0~23
	var minutes = time.getMinutes(); // 获取分钟
	var seconds = time.getSeconds(); // 获取秒数
	if (iszero) {
		// 如果小于10 数据要补充0
		month < 10 ? month = "0" + month : month;
		day < 10 ? day = "0" + day : day;
		hours < 10 ? hours = "0" + hours : hours;
		minutes < 10 ? minutes = "0" + minutes : minutes;
		seconds < 10 ? seconds = "0" + seconds : seconds;
	}
	if (!type || type == 's') {
		// 写一个函数是 yyyy-MM-dd HH:mm:ss(优化后)
		return year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
	} else if (type == 'm') {
		return year + "-" + month + "-" + day + " " + hours + ":" + minutes
	} else if (type == 'h') {
		return year + "-" + month + "-" + day + " " + hours
	} else if (type == 'day') {
		return year + "-" + month + "-" + day
	} else if (type == 'month') {
		return year + "-" + month
	} else if (type == 'year') {
		return year
	}
}
//获取屏幕高度

const getWindowHeight = (height) => {

	var h = document.documentElement.clientHeight || document.body.clientHeight;

	return h - height; //减去页面上固定高度height

}

function IsPC() {
	var userAgentInfo = navigator.userAgent;
	var Agents = ["Android", "iPhone",
		"SymbianOS", "Windows Phone",
		"iPad", "iPod"
	];
	var flag = true;
	for (var v = 0; v < Agents.length; v++) {
		if (userAgentInfo.indexOf(Agents[v]) > 0) {
			flag = false;
			break;
		}
	}
	return flag;
}

function treeData(
	data = [], //要变成树的数据
	pid = 'pid', //父级
	id = 'id', //子级
	condition = 0 //返回条件
) {
	let cloneData = JSON.parse(JSON.stringify(data)) // 对源数据深度克隆
	return cloneData.filter(father => {
		let branchArr = cloneData.filter(child => father[id] == child[pid]) //返回每一项的子级数组
		branchArr.length > 0 ? father.children = branchArr : '' //如果存在子级，则给父级添加一个children属性，并赋值
		return father[pid] == condition; //返回第一层
	});
}

function toDecimal2(x) {
	var f = parseFloat(x);
	if (isNaN(f)) {
		return false;
	}
	var f = Math.round(x * 100) / 100;
	var s = f.toString();
	var rs = s.indexOf('.');
	if (rs < 0) {
		rs = s.length;
		s += '.';
	}
	while (s.length <= rs + 2) {
		s += '0';
	}
	return s;
}

function countFloat(E, S) {
	let n = Math.pow(10, S);
	let res = Math.round(E * n) / n;
	return res;
}
const hiddenString = (str, replaceStr, startIndex = 4) => {
	return str.substr(0, startIndex) + replaceStr + str.slice(startIndex + replaceStr.length)
}

//跳转http地址
const jumpHttp = (url, type) => {
	if (!url) {
		return url
	}
	let returnUrl = ''
	let str = url.toUpperCase()
	if (str.indexOf('HTTP') == 0) {
		returnUrl = url
	} else {
		returnUrl = window.IMGHOST + url
	}
	return returnUrl
}

const find_name_by_id = (arr = [], id = 'id', name = 'name') => {
	if (!id) return '-'
	const targetManager = arr.filter(obj => obj.id == id)
	return targetManager[0][name]
}

export {
	jumpHttp,
	isPhoneNumber,
	timeToDate,
	formatMoney,
	judgmentHttp,
	formatDate,
	getWindowHeight,
	IsPC,
	treeData,
	toDecimal2,
	countFloat,
	hiddenString,
	betweenDateFormat,
}