import Vue from 'vue'
import Antd from 'ant-design-vue';
import { message } from 'ant-design-vue';
import App from './App';
import Cookies from 'js-cookie'
import router from "./router/router.js"
import * as Db from "./utils/db.js"
import * as common from './utils/common.js'
import 'ant-design-vue/dist/antd.min.css';
import './permission';
import 'vue-trix'

window.IMGHOST = 'https://img.52gfyh.com';
// window.HOST = 'http://artmapi-es.icg168.com';
// window.axios = axios;
// axios.defaults.baseURL = HOST;
// axios.defaults.timeout = 1000 * 15;
// axios.defaults.headers.Authorization = Cookies.get('artm_merchant_token');
// axios.defaults.headers['Content-Type'] = 'application/json';
Vue.config.productionTip = false;

Vue.prototype.$db = Db;
Vue.prototype.$isClick = false;
Vue.prototype.$common = common;
Vue.prototype.$Cookies = Cookies;
Vue.prototype.$message = message;
Vue.prototype.$isGetMenus = false;
Vue.prototype.$menu_list = [];

Vue.mixin({
	data() {
		return {
			
		}
	},
	methods:{
		_back(){
			this.$router.go(-1)
		}
	}
})

Vue.use(Antd,{
  i18n: function(path, options) {
    let value = i18n.t(path, options)
    if (value !== null && value !== undefined) {
      return value
    }
    return ''
  }
});
Vue.prototype.$_this = new Vue({
  el: '#app',
  router,
  render: h => h(App),
}).$mount('#app')
