<style scoped>
</style>
<template>
  <div v-if="!item.hidden" class="menu-wrapper">
    <template v-if="item.children&&item.children.length>0">
		<a-sub-menu :key="keyIndex" >
			<span slot="title"><a-icon :type="item.icon" /><span>{{item.title}}</span></span>
			<a-menu-item v-for="(t,i) in item.children" :key="menu_list.length+i">
				{{t.title}}
			</a-menu-item>
		</a-sub-menu>
	</template>
	<template v-else >
		<a-menu-item :key="key" >
		  <a-icon :type="item.icon" />
		  <span>{{item.title}}</span>
		</a-menu-item>
	</template>
  </div>
</template>

<script>
export default {
  name: 'SidebarItem',
  props: {
    // route object
    item: {
      type: Object,
      required: true
    },
    keyIndex:{
		type: [String,Number],
		default:'1'
	}
  },
  data() {
    
  },
  methods: {
    
  }
}
</script>


