import request from '@/utils/request'

// 登录
export function login(data) {
  return request({
    url: '/merchant/common/login',
    method: 'post',
    data: data
  })
}
// 退出登录
export function logout(data) {
  return request({
    url: '/merchant/common/logout',
    method: 'post',
    data: data
  })
}

//获取权限菜单
export function getMenus(data) {
  return request({
    url: '/merchant/common/menu',
    method: 'get',
    params: data
  })
}
//获取权限菜单
export function uploader(data) {
  return request({
    url: '/merchant/common/uploader',
    method: 'get',
    params: data
  })
}

//获取物流/快递公司
export function logistics(data) {
  return request({
    url: '/merchant/common/logistics',
    method: 'get',
    params: data
  })
}
//获取所有地区
export function region(data) {
  return request({
    url: '/merchant/common/region',
    method: 'get',
    params: data
  })
}

//获取所有业务经理
export function manager_index(data) {
  return request({
    url: '/merchant/ManagerUser/index',
    method: 'get',
    params: data
  })
}
//更新个人信息
export function editInfo(data) {
  return request({
    url: '/merchant/common/editInfo',
    method: 'post',
    data: data
  })
}
//清除缓存
export function delCache(data) {
  return request({
    url: '/merchant/common/delCache',
    method: 'post',
    data: data
  })
}

//获取导出列表
export function export_index(data) {
  return request({
    url: '/merchant/export/index',
    method: 'get',
    params: data
  })
}
//删除导出记录
export function export_del(data) {
  return request({
    url: '/merchant/export/del',
    method: 'post',
    data: data
  })
}

//获取未处理旅游记录数
export function undeal_traval_right(data) {
  return request({
    url: '/merchant/UserTravelRegistration/unDealTravalRight',
    method: 'get',
    params: data
  })
}