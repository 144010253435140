import axios from 'axios'
import {
  message,
  Modal
} from 'ant-design-vue';
import {
  ExclamationCircleOutlined
} from '@ant-design/icons-vue';
// import store from '@/store'
// import Cookies from 'js-cookie'
import {
  getToken,
  removeToken
} from '@/utils/auth'

// create an axios instance
const service = axios.create({
  // process.env.VUE_APP_BASE_API
  // baseURL: 'api', // url = base url + request url
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 10000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    if (getToken()) {
      config.headers['Authorization'] = getToken()
    }
    // config.headers['client'] = Cookies.get('client')?Cookies.get('client'):'iOS'
    // config.headers['language'] = Cookies.get('language')?Cookies.get('language'):'Cn'
    // config.headers['m-id'] = Cookies.get('m-id')?Cookies.get('m-id'):''
    return config
  },
  error => {
    // do something with request error
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== 200) {
      message.error(res.msg || 'Error', 5)
      if (res.code == 401) {
        Modal.confirm({
          title: '温馨提示',
          icon: createVNode(ExclamationCircleOutlined),
          content: createVNode('div', {
            style: 'color:red;'
          }, '您已退出，您可以取消停留在此页面，或重新登录'),
          onOk() {
            removeToken()
            location.reload()
          },
          onCancel() {
            removeToken()
            location.reload()
          },
          class: 'test',
        });
      }
      return Promise.reject(new Error(res.msg || 'Error'))
    } else {
      return res.result
    }
  },
  error => {
    if (error.response && error.response.data && error.response.data.code == 401) {
      Modal.confirm({
        title: '温馨提示',
        content: '您已退出，您可以取消停留在此页面，或重新登录',
        onOk() {
          removeToken()
          location.reload()
        },
        onCancel() {
          removeToken()
          location.reload()
        },
        class: 'test',
      });
    } else {
      message.error((error.response && error.response.data ? error.response.data.msg : false) || 'Error', 5)
    }
    return Promise.reject(error.response ? error.response.data : error)
  }
)

export default service