import request from '@/utils/request'

//导出列表
export function export_index(data) {
  return request({
    url: '/merchant/export/index',
    method: 'get',
    params: data
  })
}

//删除导出数据
export function export_del(data) {
  return request({
    url: '/merchant/export/del',
    method: 'post',
    data: data
  })
}

//删除导出数据
export function export_unread(data) {
  return request({
    url: '/merchant/export/unread',
    method: 'get',
    params: data
  })
}

// 用户充值列表
export function export_user_bill(data) {
  return request({
    url: '/merchant/export/userBillList',
    method: 'post',
    data: data
  })
}

// 订单明细列表
export function order_item(data) {
  return request({
    url: '/merchant/export/orderItem',
    method: 'post',
    data: data
  })
}

// 订单列表  
export function order_index(data) {
  return request({
    url: '/merchant/export/orderList',
    method: 'post',
    data: data
  })
}

// 待开单列表  
export function order_unready(data) {
  return request({
    url: '/merchant/export/unreadyList',
    method: 'post',
    data: data
  })
}

// 用户列表
export function user_list(data) {
  return request({
    url: '/merchant/export/userList',
    method: 'post',
    data: data
  })
}

//未收货商品列表  
export function undelivery_goods(data) {
  return request({
    url: '/merchant/export/unDeliveryGoods',
    method: 'post',
    data: data
  })
}
//商品规格列表 
export function goods_spec_list(data) {
  return request({
    url: '/merchant/export/goodsSpec',
    method: 'post',
    data: data
  })
}

//用户任务列表 
export function user_task(data) {
  return request({
    url: '/merchant/export/userTask',
    method: 'post',
    data: data
  })
}

//用户申请列表 
export function user_apply(data) {
  return request({
    url: '/merchant/export/userApply',
    method: 'post',
    data: data
  })
}

//用户奖品列表 
export function user_prize(data) {
  return request({
    url: '/merchant/export/userPrize',
    method: 'post',
    data: data
  })
}

//业务经理日报
export function daily_report(data) {
  return request({
    url: '/merchant/export/dailyReport',
    method: 'post',
    data: data
  })
}

//业务沟通记录
export function contact_log(data) {
  return request({
    url: '/merchant/export/contactLog',
    method: 'post',
    data: data
  })
}


//线下抽奖奖品
export function luckydraw(data) {
  return request({
    url: '/merchant/export/luckydraw',
    method: 'post',
    data: data
  })
}