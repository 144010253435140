<template>
  <div style="width:100%;height: 100%;">
	  <a-layout id="components-layout-demo-custom-trigger" style="height:100vh">
	    <a-layout-sider v-model="collapsed" :trigger="null" collapsible :style="{ overflow: 'auto', height: '100vh', position: 'fixed', left: 0 }">
	      <div class="logo" />
	      <a-menu @openChange="onOpenChange" :openKeys="openKeys" theme="dark" mode="inline" :default-selected-keys="defaultSelectedKeys" :selectedKeys="[$route.name]">
			  <template v-for="(item,index) in reversedMenu_list" v-if="!item.hidden">
				  <a-sub-menu :key="index" v-if="item.children&&item.children.length>0">
					<span slot="title">
						<!-- <a-space>
							<user-outlined />
						</a-space> -->
						<a-icon :type="item.meta.icon" />
						<span>{{item.meta.title}}</span>
					</span>
					<a-menu-item v-for="(t) in item.children" :key="t.path">
						<router-link :to="t.path">
							<span>{{t.meta.title}}</span>
						</router-link>
					</a-menu-item>
				  </a-sub-menu>
				  <a-menu-item :key="item.path" v-else >
					  <router-link :to="item.path">
						<a-icon :type="item.meta.icon" />
						<span>{{item.meta.title}}</span>
					  </router-link>
				  </a-menu-item>
			  </template>
	      </a-menu>
	    </a-layout-sider>
	    <a-layout class="adm" :style="{ marginLeft: !collapsed?'200px':'80px' }">
	      <a-layout-header class=" flex justify-between align-center" style="background: #fff; padding: 0;transition: width 0.2s;" :style="{ position: 'fixed', zIndex: 999 ,width:collapsed?'calc(100% - 80px)':'calc(100% - 200px)'}">
	        <div class=" flex justify-start align-center">
				<a-icon
				  class="trigger"
				  :type="collapsed ? 'menu-unfold' : 'menu-fold'"
				  @click="collapsedClick"
				/>
				<breadcrumb></breadcrumb>
			</div>
			<div class="pr20" >
				<a-space>
					<span v-if="traverRight"  class="fs22 color599CFB text-bold" >旅游权益( {{traverRight}} )</span>
					<a-icon type="reload" @click="reloadPage" class="fs24 cp mr10"/>
					<div class="psr">
						<a-icon type="bell" @click="showNotice()" class="fs24 cp mr10" /> 
						<div v-if="unreadNum>0" class="yuandian text-white flex justify-center align-center fs10">{{unreadNum}}</div>
					</div>
					<a-dropdown class="mr20 ml10">
						
						<div class="flex justify-start align-center">
							<img style="width: 30px;height: 30px;border-radius: 5px;" src="./image/avatar.gif" />
							<a class="ant-dropdown-link ml10" @click="e => e.preventDefault()">
							{{userInfo.username}} <a-icon type="down" />
							</a>
						</div>
						<a-menu slot="overlay">
						<a-menu-item @click="showDialog">
							修改信息
						</a-menu-item>
						<a-menu-item @click="dbDelCache">
							清除缓存
						</a-menu-item>
						<a-menu-divider />
						<a-menu-item @click="loginOut">
							退出
						</a-menu-item>
						</a-menu>
					</a-dropdown>
				</a-space>
			</div>
	      </a-layout-header>
	      <a-layout-content :style="{ margin: '80px 16px 16px',minHeight: '280px' }">
	        <router-view />
	      </a-layout-content>
	    </a-layout>
	  </a-layout>
	  <a-modal :maskClosable="false" v-model="dialogVisible" title="修改个人信息">
	  	<a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
	  		<a-form-item label="手机号">
	  			<a-input
	  				v-decorator="[
	  				  'mobile',
	  				  { rules: [
	  					  { required: true , message: '请输入手机号'}
	  					] 
	  				  },
	  				]"
	  				placeholder="请输入手机号"
	  			  />
	  		</a-form-item>
			<a-form-item label="原密码">
				<a-input
					v-decorator="[
					  'oldPwd',
					  { rules: [
						  { required: true , min: 6, max: 18, message: '请输入原密码'}
						] 
					  },
					]"
					placeholder="请输入原密码"
				  />
			</a-form-item>
	  		<a-form-item label="新密码">
	  			<a-input
	  				v-decorator="[
	  				  'newPwd',
	  				  { rules: [
	  					  { required: true , min: 6, max: 18, message: '密码长度必须为6-18个字符之间'}
	  					] 
	  				  },
	  				]"
	  				placeholder="请输入密码(6-18位)"
	  			  />
	  		</a-form-item>
	  	</a-form>
	    <template slot="footer">
	  	<a-button key="back" @click="dialogVisible=false">
	  	  取消
	  	</a-button>
	  	<a-button key="submit" type="primary" :loading="submitLoading" @click="userSubmit">
	  	  提交
	  	</a-button>
	    </template>
	    
	  </a-modal>
	  <a-modal v-model="noticeModalVisible" title="消息列表">
		<MyTable class="mt10" v-if="noticeModalVisible" :apiUrl="export_index" :params="dialogParams" ref="dialogTable" :columns="dialogColumns" :isSelection="false">
			<a-space slot='button' slot-scope="item,row,index">
				<a-button :loading="downloadLoading" size="small" @click="download(row)" type="primary" >下载</a-button>
				<a-button :loading="delExportLoading" size="small" @click="delExport(item.id)" type="danger" >删除 </a-button>
			</a-space>
			<a-space slot="error" slot-scope="item,row,index">
				<span>{{item ? "失败":"成功"}}</span>
			</a-space>
		</MyTable>
		<template slot="footer">
			<a-button type="primary" @click="noticeModalVisible=false">
				关闭
			</a-button>
		</template>
	</a-modal>
  </div>
</template>
<script>
import MyTable from "@/components/myTable.vue"
import { region,logistics,delCache,editInfo,undeal_traval_right,manager_index} from '@/api/common'
import {export_index,export_del,export_unread} from '@/api/export'
import sidbarItem from './components/sidbarItem.vue'
import breadcrumb from './components/breadcrumb.vue'
import { logout } from '@/api/common'
import { removeToken } from '@/utils/auth'
import Vue from 'vue'
export default {
  components:{sidbarItem,breadcrumb,MyTable},
  data() {
    return {
		export_index:export_index,
		collapsed: false,
		defaultSelectedKeys: [this.$route.name],
		openKeys: [''],
		userInfo:{},
		dialogVisible:false,
		unreadNum:0,
		form:this.$form.createForm(this,{
			mobile:'',
			oldPwd:'',
			newPwd:'',
		}),
		submitLoading:false,
		menu_list:[],
		delExportLoading:false,
		noticeModalVisible:false,
		downloadLoading:false,
		selectRow:[],
		dialogParams:{},
		dialogColumns:[
			{ title: '文件名', width: 200, dataIndex: 'file_name', key: 'file_name' ,scopedSlots: { customRender: 'file_name' }},
			{ title: '状态', width: 60, dataIndex: 'error', key: 'error' ,scopedSlots: { customRender: 'error' }},
			{
				title: '操作',
				key: 'operation',
				width: 100,
				scopedSlots: { customRender: 'button' },
			},
		],
		traverRight:0,
	};
  },
  computed:{
	  reversedMenu_list:()=>{
		  let menu_list =  Vue.prototype.$menu_list;
		  if(menu_list.length>0&&menu_list[0].id!=0){
			menu_list.splice(0,0, {meta:{title:'首页',icon:'home'},id:0,sort:0,hidden:false,children:[],path:'/index'})
		  }
		  return menu_list
	  }
  },
  mounted() {
	this.userInfo = JSON.parse(this.$Cookies.get('userInfo'));
	this.xhmenu()
	//判断是否需要重新获取（10天获取一次）
	let newtime = new Date().getTime()
	
	let logisticsdata = this.$db.get('logisticsList')
	if(logisticsdata){
		if((newtime - logisticsdata.time)>(24*60*60*1000)){//一天获取一次
			this.get_logistics()
		}
	}else{
		this.get_logistics()
	}
	this.get_common_region()

	//每5秒获取未一次未读消息数量
		setInterval(() => {
			this.getUnreadMsg()
		}, 5000);
	// this.getUndealTravalRight()
	//获取业务经理
	console.log("获取业务经理")
	this.get_manager_index()

  },
  methods:{
		
		//刷新页面
		reloadPage(){
			location.reload()
		},
	  //下载
		download(row){
			this.downloadLoading = true
			window.location.href = this.$common.jumpHttp(row.file)
			setTimeout(() => {
				this.downloadLoading = false
			},1000)
		},
		getUnreadMsg(){
			if(this.$db.get('is_export')){
				export_unread().then(res => {
					console.log('unread',res)
					this.unreadNum = res
				})
			}
		},
		//未处理的旅游报名
		// async getUndealTravalRight(){
		// 	this.traverRight = await undeal_traval_right()
		// },
		//显示下载
		showNotice(){
			this.noticeModalVisible = true
			this.unreadNum = 0
			this.$db.del('is_export') //没有导出任务,不一直请求
		},
		//删除下载
		delExport(id){
			this.delExportLoading = true
			export_del({id,id}).then((res)=>{
				this.$message.success('操作成功');
				setTimeout(()=>{
					this.$refs.dialogTable.getList()
					this.delExportLoading = false;
				},1500)
			}).catch(()=>{
				this.delExportLoading = false
			})
		},
		//关闭下载框
		hideDialog(){

		},
	  async dbDelCache(){
		  delCache().then((res)=>{
			//清空所有缓存
			this.$db.remove()
			this.$message.success('操作成功')
			window.location.reload()
		  })
	  },
	  // 获取省市区地址
	  async get_common_region(){
		let _this = this
		function common_region(params) {
			region().then((res)=>{
				//   this.$db.set('region',{data:this.$common.treeData(res,'pcode','code',0),time:new Date().getTime()})
				_this.$db.set('regionList',{data:res,time:new Date().getTime()})
			})
		}
		// 判断是否需要重新获取（10天获取一次）
		let newtime = new Date().getTime()
		let regiondata = this.$db.get('regionList')
		if(regiondata){
			if((newtime - regiondata.time)>(10*24*60*60*1000)){
				common_region()
			}
		}else{
			common_region()
		}
		
	  },
	  // 获取业务经理
	  async get_manager_index(){
		manager_index().then(res => {
			this.$db.set('manager_index',{data:res,time:new Date().getTime()})
		})

	  },
	  // 获取快递/物流公司
	  async get_logistics(){
		  logistics().then((res)=>{
	  		this.$db.set('logisticsList',{data:res,time:new Date().getTime()})
		  })
	  },
	  /*
	  		修改个人信息、修改成功后重新登录
	  */
	  async userSubmit(){
		  this.form.validateFields(async (err, values) => {
		  	if (!err) {
			  let model = Object.assign({}, this.form.getFieldsValue());
			  editInfo(model).then((res)=>{
				  this.$message.success('操作成功')
				  this.$Cookies.remove('artm_merchant_token')
	  			  this.$Cookies.remove('userInfo')
	  			  this.$router.push(`/login`)
			  })
			}
		  })
	  },
	  showDialog(){
		  this.dialogVisible = true
		  this.$nextTick(()=>{
		  	this.form.setFieldsValue({
		  	  mobile:this.userInfo.mobile,
		  	  oldPwd:'',
		  	  newPwd:'',
		  	});
		  })
	  },
	  
	  /*
		退出登录，清理缓存、个人信息
	  */
	  async loginOut(){
		  logout().then(()=>{
			  removeToken()
			  this.$Cookies.remove('userInfo')
			  location.reload()
		  })
	  },
	  collapsedClick(){//侧边栏展示效果
		  this.collapsed = !this.collapsed
		  if(!this.collapsed){
			  this.xhmenu()
		  }
	  },
	  onOpenChange(openKeys) {  // 当菜单被展开时触发此处
		/* 
		经测试传入的变量openKeys是数组 点击已经展开的菜单时传入的是空数组
		点击未展开的菜单时传入的是[当前展开菜单的key,点击的菜单key]
		下面的if判断用openKeys === [] 无效 所以我只能判断数组length是否等于0
		*/
		if (openKeys.length !== 0) {  
		  this.openKeys = [openKeys[1]]
		} else {
		  this.openKeys = ['']
		}
	  },
	  
	  xhmenu() {//初始化展开项
		for (let i = 0; i < this.reversedMenu_list.length; i++) { 
		//循环【顶级菜单数组列表】 循环的数组下标用i表示
		  if (this.reversedMenu_list[i].children !== false) {  
		  // 如果这个菜单没有【子菜单】 就不继续了（没有子菜单就是【点击可跳转页面但不会展开】的顶级菜单）
			for (let y = 0; y < this.reversedMenu_list[i].children.length; y++) {  
			// 循环【顶级菜单】的子菜单数组（二级菜单）数组下标用y表示
			  if (this.reversedMenu_list[i].children[y].path === this.$route.name) { 
				  // 如果子菜单的router数据和当前路由地址一样 则断定现在的页面就停留在这个数组对应的菜单中
				//   console.log(this.$route)
				this.openKeys = [i]
				console.log(this.openKeys)
				break
				// 那么就把【顶级菜单（循环数组下标为i）的router属性】赋值给this.openKeys【data中当前展开的菜单】
			  }
			}
		  }
		}
	  },
  }
};
</script>
<style>
#components-layout-demo-custom-trigger .trigger {
	display: inline-block;
  font-size: 16px;
  /* line-height: 64px; */
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
 
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}
.adm{
	transition: margin-left 0.2s ;
}
.yuandian{
	padding: 0 5px;
	height: 16px;
	background-color: red;
	border: 1px solid #fff;
	border-radius: 10px;
	position: absolute;
	right: 0;
	top: 10px;

}
</style>