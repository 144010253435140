import Vue from "vue";
import VueRouter from "vue-router";

// 引入组件
import login from "@/views/login/login.vue";
import Layout from '@/layout'
// 要告诉 vue 使用 vueRouter
Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.push = function push (location,onResolve,onReject){
	if(onResolve||onReject){
		return originalPush.call(this,location,onResolve,onReject)
	}
	return originalPush.call(this,location).catch(err => err)
}
VueRouter.prototype.originalReplace = function push (location,onResolve,onReject){
	if(onResolve||onReject){
		return originalReplace.call(this,location,onResolve,onReject)
	}
	return originalReplace.call(this,location).catch(err => err)
}

const routes = [
    {
        path:"/login",
		hidden: true,
        component: () => import('@/views/login/login.vue')
    },
	{
		path: '/redirect',
		component: Layout,
		hidden: true,
		children: [
		  {
			path: '/redirect/:path*',
			component: () => import('@/views/redirect/index')
		  }
		]
	},
	{
	    path: '/',
	    component: Layout,
	    href:'pageindex',
		redirect: 'noRedirect',
	    children: [
	      {
	        path: 'index',
	        component: () => import('@/views/index/index'),
	        name: '/index',
	        meta: { title: '首页', icon: 'home' }
	      }
	    ]
	  },
	  
	  {
		path: '/orders/detail',
		component: () => import('@/views/orders/detail'),
		name: '/orders/index',
		meta: { title: '订单详情' }
	  },
	  {
		path: '/notice/detail',
		component: () => import('@/views/notice/detail'),
		name: '/notice/index',
		meta: { title: '公告详情' }
	  },
	//   {
	// 	path:"/transaction/after_sales_detail",
	//   	hidden: true,
	// 	meta: { title: '售后详情' },
	// 	component: () => import('@/views/transaction/after_sales_detail.vue')
	//   },
	//   {
	// 	path:"/marketing/exchange_detail",
	//   	hidden: true,
	// 	meta: { title: '兑换详情' },
	// 	component: () => import('@/views/marketing/exchange_detail.vue')
	//   },
	//   {
	// 	path: '/logistics/shipping_detail',
	// 	component: () => import('@/views/logistics/shipping_detail'),
	// 	name: '/logistics/shipping_detail',
	// 	meta: { title: '运费模版' }
	//   },
	//   {
	// 	path:"/transaction/order_detail",
	//   	hidden: true,
	// 	meta: { title: '订单详情' },
	// 	component: () => import('@/views/transaction/order_detail.vue')
	//   },
    // {
    //     path: "/about",
    //     component: about
    // }
]

var router =  new VueRouter({
    routes,
	mode: "history"
})
export default router;