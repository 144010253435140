import router from './router/router.js'
import { Message } from 'ant-design-vue'
import Cookies from 'js-cookie'
import Vue from 'vue'
import Layout from '@/layout'
import NProgress from 'nprogress'
import { getToken,removeToken } from '@/utils/auth'
import { getMenus } from '@/api/common'
import 'nprogress/nprogress.css'
import getPageTitle from '@/utils/get-page-title'

NProgress.configure({ showSpinner: false }) // NProgress 配置

const whiteList = ['/login'] // 

router.beforeEach(async(to, from, next) => {
  // 开始进度条
  NProgress.start(to.path)
  if(to.path=='/noRedirect'){
    removeToken()
  }
  // 设置页面标题
  document.title = getPageTitle(to.meta.title)
  // 确定用户是否已登录
  const hasToken = getToken()
  if (hasToken) {
    if (to.path === '/login') {
      // 如果已登录，请重定向到主页
      next({ path: '/' })
      NProgress.done()
    } else {
      if(Vue.prototype.$isGetMenus){
        next()
        NProgress.done()
      }else{
        try {
          getMenus().then((data)=>{
            // const data = response.result;
            let routerdata = []
            for (let i = 0; i < data.length; i++) {
              let objArr = {
                component: Layout,
                path:data[i].href,
                children:[],
                meta:{
                  title: data[i].name, 
                  icon:  data[i].icon?data[i].icon:'ant-cloud', 
                  noCache: true
                },
                name:data[i].href
              }
              if(data[i].children.length>0){
                objArr.redirect = 'noRedirect';
                for (let j = 0; j < data[i].children.length; j++) {
                  let arr = {
                    path:data[i].children[j].href,
                    component: (resolve)=> require(['@/views'+data[i].children[j].href],resolve),
                    meta:{
                      title: data[i].children[j].name, 
                      icon:  data[i].children[j].icon, 
                      noCache: true 
                    },
                    name:data[i].children[j].href
                  }
                  objArr.children.push(arr)
                }
              }else{
                objArr.redirect = data[i].href
              }
              routerdata.push(objArr)
            }
            router.addRoutes(routerdata)
            Vue.prototype.$isGetMenus = true;
            next({ ...to, replace: true })
            NProgress.done()
            Vue.prototype.$menu_list = routerdata
          })
         
        } catch (error) {
          
          next(`/login?redirect=${to.path}`)
          NProgress.done()
        }
      }
    }
    // NProgress.done()
  } else {
	
	
    /* 没有登录能访问的*/
    if (whiteList.indexOf(to.path) !== -1) {
      let accessRoutes = []
      router.addRoutes(accessRoutes)
      next()
    }else{
      // 其他无权访问的页面将重定向到登录页面
      next(`/login?redirect=${to.path}`)
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
