//时间戳转时间格式
function get(key) {
	let item = localStorage.getItem(key)
	if (item) {
		try {
			return JSON.parse(item)
		} catch (e) {
			console.log('数据是字符串')
		}
		return item
	} else {
		return null
	}
}

function set(key, val) {
	if (typeof val != String) {
		localStorage.setItem(key, JSON.stringify(val));
	} else {
		localStorage.setItem(key, val);
	}
	return true
}

function del(key) {
	localStorage.removeItem(key);
	return true
}

function remove() {
	localStorage.clear();
	return true
}

export {
	get,
	set,
	del,
	remove,
}